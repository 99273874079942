import './knowledge-app.scss';

import Swiper from 'swiper/bundle';

class KnowledgeApp {
    constructor (element) {
        this.$knowledgeApp = element;
        this.$knowledgeAppSlider = this.$knowledgeApp.querySelector('[data-knowledge-slider="slider"]');
        this.initialize();
    }

    initialize () {
        console.log('init');
        this.initCarouselSlider();
    }

    // load slider
    initCarouselSlider () {
        if (this.$knowledgeAppSlider) {
            this.$knowledgeAppSlider = new Swiper(this.$knowledgeAppSlider, {
                autoplay: {
                    delay: 3500
                },
                height: 'auto',
                loop: true,
                allowTouchMove: false,
                slidesPerView: 'auto'
            });
        }
    }
}

export { KnowledgeApp };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $knowledgeAppSlider = $context.querySelectorAll('[data-knowledge-slider="root"]');

        if ($knowledgeAppSlider.length > 0) {
            for (let i = 0; i < $knowledgeAppSlider.length; i++) {
                const knowledgeAppSlider = new KnowledgeApp($knowledgeAppSlider[i]);
                knowledgeAppSlider.initialize();
            }
        }
    }
});
